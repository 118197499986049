import { SVGProps } from 'react';

export const Rejected = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM6.1364 4.86358C5.78493 4.51211 5.21508 4.51211 4.86361 4.86358C4.51214 5.21505 4.51214 5.7849 4.86361 6.13637L6.72721 7.99998L4.86361 9.86358C4.51214 10.2151 4.51214 10.7849 4.86361 11.1364C5.21508 11.4878 5.78493 11.4878 6.1364 11.1364L8.00001 9.27277L9.86361 11.1364C10.2151 11.4878 10.7849 11.4878 11.1364 11.1364C11.4879 10.7849 11.4879 10.2151 11.1364 9.86358L9.2728 7.99998L11.1364 6.13637C11.4879 5.7849 11.4879 5.21505 11.1364 4.86358C10.7849 4.51211 10.2151 4.51211 9.86361 4.86358L8.00001 6.72718L6.1364 4.86358Z"
        fill="#D62E1F"
      />
    </svg>
  );
};

import React, { SVGProps } from 'react';

export const SandClock = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0811 4.27927C11.8114 3.79243 12.25 2.97282 12.25 2.09514V1.75C12.25 0.783502 11.4665 0 10.5 0H3.5C2.5335 0 1.75 0.783503 1.75 1.75V2.09514C1.75 2.97282 2.18864 3.79243 2.91891 4.27927L5.6875 6.125C5.7102 6.13655 5.72164 6.14896 5.74327 6.16218L5.8074 6.20493C6.05285 6.38287 6.2125 6.67194 6.2125 6.99829C6.2125 7.38218 6.00912 7.71448 5.6875 7.875L2.91891 9.72073C2.18864 10.2076 1.75 11.0272 1.75 11.9049V12.25C1.75 13.2165 2.5335 14 3.5 14H10.5C11.4665 14 12.25 13.2165 12.25 12.25V11.9049C12.25 11.0272 11.8114 10.2076 11.0811 9.72073L8.3125 7.875C7.99088 7.71448 7.7875 7.38218 7.7875 6.99829C7.7875 6.67195 7.94714 6.38289 8.19259 6.20494L8.25674 6.16217C8.27837 6.14895 8.28981 6.13655 8.3125 6.125L11.0811 4.27927ZM7 5.10709L10.2074 2.96879C10.4995 2.77406 10.675 2.44621 10.675 2.09514V1.75C10.675 1.65335 10.5966 1.575 10.5 1.575L3.5 1.575C3.40335 1.575 3.325 1.65335 3.325 1.75V2.09514C3.325 2.44621 3.50046 2.77406 3.79256 2.9688L7 5.10709Z"
        fill="currentcolor"
      />
    </svg>
  );
};
